<template>
  <main>
    <ThisComponent
      text-enter-phone-number="Введи свой номер телефона"
      text-enter-code="Введи код из SMS"
    >
      <template #default>
        <h1>Личный кабинет</h1>
        <p>Добро пожаловать на новогоднюю ярмарку «Сбудется», где ты сможешь найти для себя счастье и новые возможности.</p>
        <hr>
      </template>
      <template #auth-error>
        <div class="alert__heading">Ошибка авторизации</div>
        <p>Если не получается зайти, пиши <a href="mailto:hq_podarok@megafon-retail.ru">hq_podarok@megafon-retail.ru</a></p>
      </template>
    </ThisComponent>
  </main>
</template>

<script>
import ThisComponent from 'base/components/AuthorizationSmsCode'

ThisComponent.mounted = function () {
  this.$store.commit('app/updatePageClass', 'page--auth')

  this.afterMounted()
}

export default {
  components: {
    ThisComponent
  }
}
</script>

<style lang="css" scoped>
  main {
    margin: auto auto 0;
  }

  h1 {
    font-size: 1.8em;
    margin-top: 0;
  }

  .form {
    margin-bottom: 20px;
    background: rgba(255, 255, 255, 0.9);
    padding: 20px;
    border-radius: var(--border-radius);
    max-width: 520px;
    box-shadow: 0 0 16px rgba(0, 0, 0, .15);
  }

  .step__fields .form-group {
    margin-bottom: 10px;
  }

  .form-field {
    width: 100%;
  }

  .form .button {
    white-space: nowrap;
  }

  @media (min-width: 480px) {
    .step__fields {
      display: -webkit-flex;
      display: -ms-flex;
      display: flex;
    }

    .step__fields .form-group {
      margin-right: 10px;
      margin-bottom: 0;
    }
  }
</style>
